import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const w01EAT05Merkhilfen = () => (
  <Layout>
    <SEO title="w01EAT05Merkhilfen" />

    <h1 id="ueberschrift">Eigenorganisation Spezial</h1>
    <h2>Wie schaffe ich Merkhilfen? &emsp;  ©&nbsp;1991</h2>

      <p>
        Kontakt:&ensp;
        <a href="mailto:iarna@bastet-project.de?subject=Anfrage zu w01EAT05Merkhilfen&amp;body=Bitte senden Sie so viele Daten wie möglich (z.B.: gewünschter Ort, Tag, Anzahl der Teilnehmer, Altersgruppe, etc.)." title="per E-Mail kontaktieren" style={{ fontSize: "1em" }}>iarna@bastet-project.de</a>
      </p>

      <p>
        Spätestens wenn die Frage aufkommt, wie "xy" vergessen werden konnte oder warum "z" vergeblich gesucht wird,
        ploppt zumindest für einen Bruchteil einer Sekunde der Wunsch nach einer besseren Organisation auf.
        <br />
        Doch wie lässt sich das bewerkstelligen? Man tut doch schon so viel!
      </p>
      <p>  
        *Eigenorganisation* ist mehr als nur die Autoschlüssel ans Schlüsselbrett zu hängen oder einen Termin
        sogleich in den Terminkalender einzutragen. Und *Eigenorganisation* ist auch weniger als eben dieses.
      </p>
      <p>
        Meist ist es einfacher etwas zu verstehen, das erlebt oder zumindest angeschaut werden kann.
        Aus diesem Grund bietet dieser Kurs mehr als einen einfachen Frontal-Vortrag. Durch Verwendung
        verschiedener Techniken, welche sogleich etwas geübt werden, kann dem Gedächtnis geholfen werden.
        z.&nbsp;B.: Lustige Geschichten oder Bilder prägen sich oftmals leichter ein, als trockene Fakten.
        Doch wie malt man Bilder ohne das geringste Talent zum Zeichnen oder wie erfindet man eine Geschichte
        wenn gerade die Muse spazieren gegangen ist? Durch die Mischung meiner
        Kenntnisse mit Ihren Situationen und Interessen können wir den Kursinhalt weitmöglichst Ihren Bedürfnissen anpassen.
        So können Sie Methoden entwickeln, welche Ihnem Gedächtnis beim Merken und Erinnern helfen können.
      </p>
      <p style={{fontStyle: "italic", textDecoration: "underline"}}>
        Vortrag (+Mitmachkurs)
      </p>
      <p>
        für alle Altersgruppen<br/>
        Platzbedarf: 1 Tisch pro Teilnehmer + 1 Tisch extra<br/>
        Materialbedarf: + Kugelschreiber und Schmierpapier +
      </p>
      <p>
        Privatstunden möglich<br/>
        Platzbedarf: 1 Tisch
      </p>
      <p>
        + keine besonderen Voraussetzungen nötig +
      </p>

    <Link to="/fdml_1a_aktuelles-klartext/" title="Detaillierte Informationen zum Bereich Veranstaltungen &amp; mehr" >Aktuelle Termine</Link>
      <p></p>
  </Layout>
              )

              export default w01EAT05Merkhilfen
